<template>
  <section id="filing-order-details">
    <ct-centered-spinner v-if="!loaded" />
    <template v-else>
      <template v-if="filingOrderStatusIsSubmittedToState">
        <h3>
          Your order was submitted to the state on {{ currentStatusDate }}.
        </h3>
        <p>
          The state processing time is {{ stateProcessingInDays }} business days. Sit tight, we will
          update the page and send you notification when the paperwork has been processed.
        </p>
      </template>

      <template v-else-if="filingOrderStatusIsCompleted">
        <h3>
          Your filing has been completed and it was filed with the state on
          {{ currentStatusDate }}.
        </h3>
        <template v-if="hasDocument">
          <div class="text-with-button">
            <p>
              Click here to view a copy of your filed document.
            </p>
            <button class="primary" aria-label="view document button">
              View Document
            </button>
          </div>
        </template>
        <template v-else>
          <button
            class="btn next-button btn-primary"
            aria-label="review your filed document button"
            @click="nextSlide"
          >
            Review Your Filed Document
          </button>
        </template>
      </template>

      <template v-else-if="pendingClientActions.includes(currentFilingStatus)">
        <h3>
          Client Action Required
        </h3>
        <div class="text-with-button">
          <p>
            Whoops! It looks like we still need some information before submitting to the state.
          </p>
          <button class="primary" aria-label="update filing button" @click="routeToClientAction">
            Update Filing
          </button>
        </div>
      </template>

      <template v-else-if="cancelledStatuses.includes(currentFilingStatus)">
        <h3>
          Your filing has been {{ currentFilingStatus }}.
        </h3>
      </template>

      <template v-else>
        <h3>
          We've received and are processing your filing.
        </h3>
      </template>

      <template>
        <primary-button
          class="setup-services-button"
          button-text="Continue Setup of Services"
          aria-label="setup services button"
          :show="showSetupBusinessIdentityServices"
          @onclick="navigateToOnlinePresenceStage"
        />
      </template>
    </template>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FilingOrderDetails',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    PrimaryButton:     () => import('@/components/StagelineV2/shared/PrimaryButton'),
  },
  props: {
    cancelledStatuses: {
      type: Array,
      default: () => [],
    },
    currentFilingStatus: {
      type: String,
      default: '',
    },
    pendingClientActions: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      default: () => {},
    },
    statusUpdatedAt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      hasDocument: false, //TODO: future ticket --> waiting on document upload project
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'isStagelineRoute',
      'stageHasIncompleteConfigurationSlides',
    ]),
    currentStatusDate() {
      const datetime = this.statusUpdatedAt.split('T')
      if (datetime.length === 1) return this.statusUpdatedAt

      const date = datetime[0].split('-')

      return `${date[1]}/${date[2]}/${date[0]}`
    },
    filingOrderStatusIsCompleted() {
      return this.currentFilingStatus === 'completed'
    },
    filingOrderStatusIsSubmittedToState() {
      return this.currentFilingStatus === 'submitted-to-state'
    },
    stateProcessingInDays() {
      return this.product?.filing_method?.filed_in?.days || ''
    },
    showSetupBusinessIdentityServices() {
      return this.isStagelineRoute &&
        (this.stageHasIncompleteConfigurationSlides('configure_domain') || this.stageHasIncompleteConfigurationSlides('configure_hosting')) &&
        !this.filingOrderStatusIsCompleted
    },
  },
  async mounted() {
    this.loaded = true
  },
  methods: {
    ...mapActions('stageline', ['navigateToStageByName']),
    async routeToClientAction() {
      await this.$router.push({ name: 'orderItemsRequiringAttention' }).catch(() => {})
    },
    async navigateToOnlinePresenceStage() {
      this.loaded = false

      if (this.this.stageHasIncompleteConfigurationSlides('configure_domain')) {
        await this.navigateToStageByName('configure_domain')
      } else if (this.stageHasIncompleteConfigurationSlides('configure_hosting')) {
        await this.navigateToStageByName('configure_hosting')
      }
    },
    nextSlide() {
      this.$emit('next-slide')
    },
  },
}
</script>
<style lang="scss" scoped>
// Palette
$black1: #231F20;
$teal1: #2FB2AE;
$red1: #F45151;
$red3: #BC2F2F;

// Typography
// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $ct-ui-font-weight-4, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $black1;
}

// Typography: Font
h3 {
  @include typography(
    $font-size: 1.5em,
    $font-weight: $ct-ui-font-weight-7
  );
}

p {
  @include typography();
}

// Buttons
// Buttons: Mixin
@mixin btn-core($padding, $font-size, $hover-color) {
  width: fit-content;
  border-width: 0.0625em;
  border-style: solid;
  border-radius: 0.25em;
  padding: $padding;
  outline: 0;
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $ct-ui-font-weight-7;
  &:hover {
    border-color: $hover-color;
    background: $hover-color;
    color: white;
  }
}

@mixin btn-default($primary-color, $hover-color, $padding: 0.6em 2.0em, $font-size: 1.25em) {
  background: $primary-color;
  color: white;
  border-color: $primary-color;
  @include btn-core($padding, $font-size, $hover-color);
}

// Buttons: Types
button.primary {
  @include btn-default(
    $primary-color: $teal1,
    $hover-color: $ct-ui-color-26
  );
}

button.alert {
  @include btn-default(
    $primary-color: $red1,
    $hover-color: $red3
  );
}

// Normalize
* {
  padding: 0;
  margin: 0;
}

// Component
section#filing-order-details {
  position: relative;
  height: inherit;

  h3 {
    padding-top: 1.0em;
  }

  div.text-with-button {
    padding-top: 1.0em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    row-gap: 1.0em;
  }

  .btn.next-button {
    margin-top: 3em;
  }

  .setup-services-button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  section#filing-order-details{
      padding-left: 3.35em;
      margin-top: -2em;

      h3{
        display: none;
      }
  }
}
@media only screen and (max-width: 767px) {
  .filing-order-container {
    flex-direction: column;
  }
}
</style>
