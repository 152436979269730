import { render, staticRenderFns } from "./FilingOrderDetails.vue?vue&type=template&id=d51161fc&scoped=true"
import script from "./FilingOrderDetails.vue?vue&type=script&lang=js"
export * from "./FilingOrderDetails.vue?vue&type=script&lang=js"
import style0 from "./FilingOrderDetails.vue?vue&type=style&index=0&id=d51161fc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d51161fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d51161fc')) {
      api.createRecord('d51161fc', component.options)
    } else {
      api.reload('d51161fc', component.options)
    }
    module.hot.accept("./FilingOrderDetails.vue?vue&type=template&id=d51161fc&scoped=true", function () {
      api.rerender('d51161fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Filing/PendingFiling/FilingOrderDetails.vue"
export default component.exports