var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { attrs: { id: "filing-order-details" } },
    [
      !_vm.loaded
        ? _c("ct-centered-spinner")
        : [
            _vm.filingOrderStatusIsSubmittedToState
              ? [
                  _c("h3", [
                    _vm._v(
                      "\n        Your order was submitted to the state on " +
                        _vm._s(_vm.currentStatusDate) +
                        ".\n      "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n        The state processing time is " +
                        _vm._s(_vm.stateProcessingInDays) +
                        " business days. Sit tight, we will\n        update the page and send you notification when the paperwork has been processed.\n      "
                    ),
                  ]),
                ]
              : _vm.filingOrderStatusIsCompleted
              ? [
                  _c("h3", [
                    _vm._v(
                      "\n        Your filing has been completed and it was filed with the state on\n        " +
                        _vm._s(_vm.currentStatusDate) +
                        ".\n      "
                    ),
                  ]),
                  _vm.hasDocument
                    ? [
                        _c("div", { staticClass: "text-with-button" }, [
                          _c("p", [
                            _vm._v(
                              "\n            Click here to view a copy of your filed document.\n          "
                            ),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "primary",
                              attrs: { "aria-label": "view document button" },
                            },
                            [_vm._v("\n            View Document\n          ")]
                          ),
                        ]),
                      ]
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "btn next-button btn-primary",
                            attrs: {
                              "aria-label": "review your filed document button",
                            },
                            on: { click: _vm.nextSlide },
                          },
                          [
                            _vm._v(
                              "\n          Review Your Filed Document\n        "
                            ),
                          ]
                        ),
                      ],
                ]
              : _vm.pendingClientActions.includes(_vm.currentFilingStatus)
              ? [
                  _c("h3", [
                    _vm._v("\n        Client Action Required\n      "),
                  ]),
                  _c("div", { staticClass: "text-with-button" }, [
                    _c("p", [
                      _vm._v(
                        "\n          Whoops! It looks like we still need some information before submitting to the state.\n        "
                      ),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { "aria-label": "update filing button" },
                        on: { click: _vm.routeToClientAction },
                      },
                      [_vm._v("\n          Update Filing\n        ")]
                    ),
                  ]),
                ]
              : _vm.cancelledStatuses.includes(_vm.currentFilingStatus)
              ? [
                  _c("h3", [
                    _vm._v(
                      "\n        Your filing has been " +
                        _vm._s(_vm.currentFilingStatus) +
                        ".\n      "
                    ),
                  ]),
                ]
              : [
                  _c("h3", [
                    _vm._v(
                      "\n        We've received and are processing your filing.\n      "
                    ),
                  ]),
                ],
            [
              _c("primary-button", {
                staticClass: "setup-services-button",
                attrs: {
                  "button-text": "Continue Setup of Services",
                  "aria-label": "setup services button",
                  show: _vm.showSetupBusinessIdentityServices,
                },
                on: { onclick: _vm.navigateToOnlinePresenceStage },
              }),
            ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }